<template>
  <ProfileCard
    :name="data.name"
    :credits="data.credits"
    :tokens="data.tokens"
    @newAvatar="setAvatar"
  />
  <form @submit.prevent="handleSubmit(v$.$invalid)" class="_form _relative">
    <Loader v-if="isLoading" isOverlay />
    <label class="p-field mb-4" :class="{'p-invalid': v$.name.$invalid && v$.name.$dirty}">
      <span class="p-label" v-t="'username'"></span>
      <InputText type="text" v-model="v$.name.$model" @blur="v$.name.$touch()" />
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.email.$invalid && v$.email.$dirty}">
      <span class="p-label" v-t="'email'"></span>
      <InputText type="email" v-model="v$.email.$model" @blur="v$.email.$touch()" />
      <span class="p-hint">This is your login</span>
    </label>
    <label class="p-field mb-4" :class="{'p-invalid': v$.phone.$invalid && v$.phone.$dirty}">
      <span class="p-label" v-t="'profile.phone'"></span>
      <InputText type="tel" v-model="v$.phone.$model" @blur="v$.phone.$touch()" />
    </label>

    <button class="mb-4 _link" v-t="isShow ? 'cancel' : 'profile.change_password'" type="button" @click="isShow = !isShow"></button>

    <div class="_password-box mb-4" v-if="isShow">
      <label class="p-field mb-4" :class="{'p-invalid': v$Password.old_password.$invalid && v$Password.old_password.$dirty}">
        <span class="p-label" v-t="'old_password'"></span>
        <InputText type="password" v-model="v$Password.old_password.$model" @blur="v$Password.old_password.$touch()" />
      </label>
      <label class="p-field mb-4" :class="{'p-invalid': v$Password.new_password.$invalid && v$Password.new_password.$dirty}">
        <span class="p-label" v-t="'new_password'"></span>
        <InputText type="password" v-model="v$Password.new_password.$model" @blur="v$Password.new_password.$touch()" />
        <span v-if="v$Password.new_password.$invalid && v$Password.new_password.$dirty && v$Password.new_password.minLength">
          {{$t('profile.password_min_length', { number: 6 })}}
        </span>
      </label>
      <label class="p-field" :class="{'p-invalid': v$Password.replay_new_password.$invalid && v$Password.replay_new_password.$dirty}">
        <span class="p-label" v-t="'replay_new_password'"></span>
        <InputText type="password" v-model="v$Password.replay_new_password.$model" @blur="v$Password.replay_new_password.$touch()" />
        <span v-if="v$Password.replay_new_password.$dirty && v$Password.new_password.$model && v$Password.replay_new_password.$model !== v$Password.new_password.$model" v-t="'profile.password_confirm'" />
      </label>
    </div>

    <label class="p-field mb-4">
      <span class="p-label" v-t="'profile.total_credit'"></span>
      <InputNumber type="text" v-model="data.credits" />
    </label>
    <label class="p-field mb-4">
      <span class="p-label" v-t="'profile.available_tokens'"></span>
      <InputNumber type="text" v-model="data.tokens" />
    </label>

    <div class="grid">
      <Button class="p-button-big col mt-3 ml-2 mr-2" type="submit" :disabled="isSubmiting"><span v-t="'save_change'"></span></Button>
      <Button class="p-button-big p-button-outlined p-button-gray p-button-icon-right col mt-3 ml-2 mr-2" @click="toggleBlock">
        <span v-t="data.is_active ? 'block' : 'unblock'"></span>
        <Icon :name="data.is_active ? 'unlock' : 'lock'" />
      </Button>
    </div>
  </form>
</template>

<script>
import { ref, reactive } from 'vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useVuelidate } from "@vuelidate/core";
import { useI18n } from 'vue-i18n'
import { useToast } from "primevue/usetoast";
import { email, required, minLength } from "@vuelidate/validators";

import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InputNumber from 'primevue/inputnumber';

import { Icon, Loader } from '../../../components/ui'
import { ProfileCard } from '../../../components/common'

export default {
  components: {
    InputNumber,
    InputText,
    Button,
    ProfileCard,
    Icon,
    Loader,
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const { t } = useI18n()
    const toast = useToast()

    store.dispatch('companyUser/reqBase', { user: route.params.userID })
    const data = computed(() => store.state.companyUser.data || {})
    const isLoading = computed(() => store.state.companyUser.isLoading);
    const isError = computed(() => store.state.companyUser.isError);
    const isSubmiting = computed(() => store.state.companyUser.isSubmiting);

    const isShow = ref(false)
    const password = reactive({
      old_password: '',
      new_password: '',
      replay_new_password: '',
    })

    const rules = reactive({
      email: { required, email },
      name: { required },
      phone: { required },
    })

    const rulesForPassword = reactive({
      old_password: { required },
      new_password: { required, minLength: minLength(6) },
      replay_new_password: { required }
    });


    const v$ = useVuelidate(rules, data)
    const v$Password = useVuelidate(rulesForPassword, password)

    const handleSubmit = (isFormValid, isPasswordValid) => {
      let body = {...data.value}
      v$.value.$touch()
      if (isShow.value) {
        v$Password.value.$touch()
      }
      if (isFormValid || isShow.value && isPasswordValid && password.replay_new_password !== password.new_password) {
        return;
      }
      if (isShow.value) {
        body = {...body, ...password, user: body.id}
      }

      store.dispatch('companyUser/sendBase', {
        body,
        toast,
        t
      })
    }

    const setAvatar = (file) => {
      data.value.avatar = file
    }

    const toggleBlock = () => {
      store.dispatch('companyUser/reqToggleBlock')
    }

    return {
      v$,
      v$Password,
      data,
      isShow,
      password,
      handleSubmit,
      isLoading,
      isError,
      isSubmiting,
      toggleBlock,
      setAvatar,
    }
  }
}
</script>
